import React from 'react';

import Layout from '../components/layout';
import Map from '../components/SiteMap';

const SiteMap = () => {
  return (
    <Layout>
      <Map />
    </Layout>
  );
};

export default SiteMap;
